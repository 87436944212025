import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { aboutusList, aboutusAdd, aboutusUpdate, aboutusDelete, aboutusListById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "aboutus";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    aboutusDatas: null,
    aboutusCount: 0,
    aboutusDataById: null
};

export const aboutusData = createAsyncThunk(
    `${namespace}/aboutusData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await aboutusList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddAboutus = createAsyncThunk(
    `${namespace}/AddAboutus`,
    async ({ postDatas, uploadfiles }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            uploadfiles.forEach((file) => {
                console.log(file);
                postData.append("uploadedFile", file);
            })
            postData.append("payload", payload);
            const data = await aboutusAdd(postData);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateAboutus = createAsyncThunk(
    `${namespace}/UpdateAboutus`,
    async ({ postDatas, uploadfiles, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            console.log(postDatas, "interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            if (uploadfiles) {
                uploadfiles.forEach((file) => {
                    console.log(file);
                    postData.append("uploadedFile", file);
                })
            } else {
                postData.append("uploadedFile", []);
            }
            postData.append("payload", payload);
            const data = await aboutusUpdate(postData, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteAboutus = createAsyncThunk(
    `${namespace}/DeleteAboutus`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await aboutusDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const aboutusById = createAsyncThunk(
    `${namespace}/aboutusById`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await aboutusListById(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const aboutusSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearAboutLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearAboutAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearAboutUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearAboutDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [aboutusData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [aboutusData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.aboutusCount = payload?.data?.count;
            // state.aboutusDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count, "payload")
            state.aboutusCount = payloaddatas?.count;
            state.aboutusDatas = payloaddatas?.data;
        },
        [aboutusData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddAboutus.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddAboutus.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddAboutus.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateAboutus.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateAboutus.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateAboutus.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteAboutus.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteAboutus.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteAboutus.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [aboutusById.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [aboutusById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.aboutusDataById = payloaddatas?.response;
        },
        [aboutusById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        }
    },
});

export const { clearData, clearLoadingDatas, clearAboutAddLoading, clearAboutUpdateLoading, clearAboutDeleteLoading, clearErrormsg } = aboutusSlice.actions;

export const aboutusSelector = (state) => state.aboutus;

export default aboutusSlice.reducer;

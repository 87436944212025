import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { custommenuList,custommenuListById, custommenuAdd, custommenuUpdate, custommenuDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "custommenu";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    custommenuDatas: null,
    custommenuCount: 0,
    custommenuDatasbyID: null
};

export const custommenuData = createAsyncThunk(
    `${namespace}/custommenuData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", include ="", status ="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuList(query, page, limit, sortby, order, include, status);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const custommenuDatabyID = createAsyncThunk(
    `${namespace}/custommenuDatabyID`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuListById({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const AddCustommenu = createAsyncThunk(
    `${namespace}/AddCustommenu`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateCustommenu = createAsyncThunk(
    `${namespace}/UpdateCustommenu`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteCustommenu = createAsyncThunk(
    `${namespace}/DeleteCustommenu`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await custommenuDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const custommenuSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearCMLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearCMAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearCMUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearCMDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [custommenuData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [custommenuData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.custommenuCount = payload?.data?.count;
            // state.custommenuDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.custommenuCount = payloaddatas?.count;
            state.custommenuDatas = payloaddatas?.data;
        },
        [custommenuData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [custommenuDatabyID.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [custommenuDatabyID.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.custommenuCount = payload?.data?.count;
            // state.custommenuDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data, secretKey));
            console.log(payloaddatas?.data,"payload")
            state.custommenuDatasbyID = payloaddatas?.data;
        },
        [custommenuDatabyID.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddCustommenu.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddCustommenu.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddCustommenu.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateCustommenu.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateCustommenu.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateCustommenu.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteCustommenu.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteCustommenu.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteCustommenu.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearCMAddLoading, clearCMUpdateLoading, clearCMDeleteLoading, clearErrormsg } = custommenuSlice.actions;

export const custommenuSelector = (state) => state.custommenu;

export default custommenuSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logo from "../assets/images/horizon-full.svg";
import LogoWhite from "../assets/images/horizon-full.svg";
import UserImage from "../assets/images/user.png";
import Avatar12 from "../assets/images/xs/avatar12.jpg";
import Avatar11 from "../assets/images/xs/avatar11.jpg";
import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import { Leftmenu } from "../services/leftmenu";
import { logOut } from "../store/reducer/login"
import _ from 'lodash';
function NavBar() {
    const dispatch = useDispatch();
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    //let activeKey = activeKey;
    //console.log(activeKey, "activeKey");
    const handleClick = () => {

    };
    const [menuDetails, setMenuDetails] = useState(Leftmenu);
    const [toggleMenu, setToggleMenu] = useState(true);
    let [activeKey, setactiveKey] = useState(res);
    const activeMenutabContainer = (id) => {
        var parents = document.getElementById("main-menu");
        var activeMenu = document.getElementById(id);
        let check = 0;
        for (let index = 0; index < parents.children.length; index++) {
            if (parents.children[index].id !== id) {
                parents.children[index]?.classList.remove("active");
                parents.children[index].children[1]?.classList.remove("in");
            } else {
                check = 1;
                parents.children[index]?.classList.add("active");
            }
        }
        console.log(check, "check");
        setTimeout(() => {
            console.log("in");
            if (check == 1) {
                activeMenu.classList.add("active");
                activeMenu.children[1].classList.add("in");
            }
            else {
                activeMenu.classList.toggle("active");
                activeMenu.children[1].classList.toggle("in");
            }
        }, 100);

    };
    const user_name = localStorage.getItem("username")

    const onToggleMenu = () => {
        setToggleMenu(!toggleMenu);
        if (!toggleMenu) {
            document.body.classList.remove("layout-fullwidth");
        } else {
            document.body.classList.add("layout-fullwidth");
        }
    };

    const MarkActiveSub = (active, sub) => {
        activeKey = sub;
        setactiveKey(sub);
    }

    const logout = () => {
        dispatch(logOut());
    }

    useEffect(() => {
        if (activeKey) {
            let getcontainer = menuDetails.filter((menu) => {
                if (_.includes(menu.url, activeKey)) {
                    return menu.container;
                }
            })
            //let get_c = _.some(menuDetails, (el) => _.includes(el.url, activeKey))

            //console.log(menuDetails, getcontainer[0].container, "menuDetails")
            if (getcontainer.length > 0)
                activeMenutabContainer(getcontainer[0].container)

        }
    }, [activeKey])


    return (

        <div>
            <nav className="navbar navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-btn">
                        <button
                            className="btn-toggle-offcanvas"
                            onClick={() => {
                                onToggleMenu()
                            }}
                        >
                            <i className="lnr lnr-menu fa fa-bars"></i>
                        </button>
                    </div>

                    <div className="navbar-brand">
                        <a href="/dashboard">
                            <img
                                src={
                                    document.body.classList.contains("full-dark")
                                        ? LogoWhite
                                        : Logo
                                }
                                alt="Horizon Logo"
                                className="img-responsive logo"
                            />
                        </a>
                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">
                                <li>
                                    <a href="javascript:void(0)" onClick={logout} className="icon-menu">
                                        <i className="icon-login"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
                <div className="sidebar-scroll">
                    <div className="user-account">
                        <img
                            src={Avatar12}
                            className="rounded-circle user-photo"
                            alt="User Profile Picture"
                        />
                        <Dropdown>
                            <span>Welcome,</span>
                            <Dropdown.Toggle
                                variant="none"
                                as="a"
                                id="dropdown-basic"
                                className="user-name"
                            >
                                <strong>{user_name}</strong>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-right account">
                                {/* <Dropdown.Item href="profilev2page">
                                    <i className="icon-user"></i>My Profile
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    {" "}
                                    <i className="icon-settings"></i>Settings
                                </Dropdown.Item> */}
                                <li className="divider"></li>
                                <Dropdown.Item href="javascript:void(0)" onClick={logout}>
                                    {" "}
                                    <i className="icon-power"></i>Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <hr />
                    </div>
                    <div className="tab-content p-l-0 p-r-0">
                        <div className={"tab-pane active show"} id="menu">
                            <Nav id="left-sidebar-nav" className="sidebar-nav">
                                <ul id="main-menu" className="metismenu">
                                    {menuDetails != null && menuDetails.map((menu) => {
                                        return (menu?.ischild) ? (
                                            <li className="" id={menu?.container} >
                                                <a href="#!" className={(menu?.ischild) ? "has-arrow" : ""} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); }}>
                                                    <i className={menu?.icon}></i> <span>{menu?.menu}</span>
                                                </a>
                                                {(menu?.ischild) ? (
                                                    <ul className="collapse">
                                                        {menu?.child.map((submenu) => {
                                                            return (<li
                                                                className={(activeKey === submenu.url) ? "active" : ""} onClick={(e) => { e.preventDefault(); MarkActiveSub(activeKey, submenu.url); }}
                                                            >
                                                                <Link to={submenu?.url}>{submenu?.menu}</Link>
                                                            </li>)
                                                        })}
                                                    </ul>
                                                ) : ''}
                                            </li>

                                        ) : (
                                            <li className="" id={menu?.container} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); MarkActiveSub(activeKey, menu.url); }}>
                                                <Link to={menu?.url}><i className={menu?.icon}></i> <span>{menu?.menu}</span></Link>
                                            </li>
                                        )
                                    })
                                    }

                                </ul>
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )


}
export default NavBar;

export const productFields = [
    { label: "Product Name *", type: "text", name: "name", placeHolder: "Product Name", class: "", value: "", input: "text", validation: "required|max:30" },
    { label: "Item Code *", type: "text", name: "item_code", placeHolder: "Item Code", class: "", value: "", input: "text", validation: "required|alpha_num_space" },
    { label: "Category *", type: "text", name: "category_id", placeHolder: "Category", class: "", value: [], input: "dropdown", validation: "required", options:"category" },
    { label: "Sub Category *", type: "text", name: "subcategory_id", placeHolder: "Sub Category", class: "", value: [], input: "dropdown", validation: "required", options:"subcategory"},
    { label: "Description", type: "text", name: "description", placeHolder: "Product Description", class: "", value: "", input: "textarea", validation: "" },
    { label: "Additional Information", type: "text", name: "additional_information", placeHolder: "Additional Information", class: "", value: "", input: "textarea", validation: "" },
    { label: "Reward", type: "text", name: "reward", placeHolder: "If REWARD option enabled the particular product will be added to the reward product list.", class: "", value: 0, input: "checkbox", validation: "" },
    { label: "Item Size *", type: "text", name: "itemsize_ids", placeHolder: "Item Size", class: "", value: [], input: "dropdown", validation: "required" ,dynamic_data:true, multiple:true, options:"itemsize"},
    { label: "Default Size *", type: "text", name: "default_size", placeHolder: "Default Size", class: "", value: [], input: "dropdown", validation: "required", options:"defaultsize" },
    { label: "Weight OR Min & Max *", type: "text", name: "uom", placeHolder: "Weight OR Min & Max", class: "", value: "0", input: "radio", validation: "", options:[{value:0, option:"Weight", checked:"checked"},{value:1, option:"Min & Max", checked:""}] },
    { label: "Weight *", type: "text", name: "weight", placeHolder: "Weight", class: "", value: "", input: "text", validation: "required" },
    { label: "Status *", type: "text", name: "status", placeHolder: "Status", class: "", value: [{ name: "Active",id: "1" }], input: "dropdown", validation: "", options:"status" },
    { label: "Image *", type: "file", name: "image", placeHolder: "Upload Image Dimension Should be 500px X 600px", class: "custom-file-input", value: "", input: "file", validation: "" },
    { label: "Custom Menus *", type: "file", name: "custom_menus", placeHolder: "Custom Menu", class: "custom-file-input", value: "0", input: "radio", validation: "", options:[{value:1, option:"Yes", checked:""},{value:0, option:"No", checked:"checked"}] }];
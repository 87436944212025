import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    orderData,
    orderSelector,
    clearLoadingDatas,
    clearData,
    clearErrormsg
} from "../store/reducer/order";

import {
    customerData,
    customersSelector
} from "../store/reducer/customers";

import { API_STATUS } from "../utils/constants";

import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../services/config";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

function Orders() {

    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { loading, orderDatas, orderCount, errorMessage } = useSelector(orderSelector);

    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0); 
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("id");
    let [activesortBy, setActiveSortBy] = useState("id");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");


    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        orderDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        orderDispatch();
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        orderDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        orderDispatch();
    };

    const orderDispatch = () => {
        dispatch(
            orderData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        orderDispatch()
        dispatch(customerData({}));
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [errorMessage]);

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Orders"
                    Breadcrumb={[{ name: "Orders" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Orders</h2>
                                    </div>
                                    <div className="col-md-4 row">
                                        <div className="col-md-12">
                                            <input type="text" name="search" className="form-control" value={searchQuery}
                                                onChange={(e) => { searchData(e) }} placeholder="Search by Order Id" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("id");
                                                    }}
                                                >
                                                    Order ID
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "customerid" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("customerid");
                                                    }}
                                                >
                                                    Customer Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "order_total" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("order_total");
                                                    }}
                                                >
                                                    Total Payment (in $)
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "created_at" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("created_at");
                                                    }}
                                                >
                                                    Sale Date
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "order_status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("order_status");
                                                    }}
                                                >
                                                    Order Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "print_status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("print_status");
                                                    }}
                                                >
                                                    Print Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>

                                            <StyledTableCell style={{ width: "100px" }}  align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            orderDatas && orderDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {(row.customer_id!=null)? row?.customer?.first_name + " "+ row?.customer?.last_name:""}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.order_total}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {dayjs(row.created_at).format(DateFormat)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.order_status == 0 ? "Pending" : "Confirmed" }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.print_status == 1 ? "Printed" : "pending"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status == 1 ? "Active" : "In-active" }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <Link to={'/orderdetails/' + row.id} > <i className="icon-eye text-info p-2" title="View"></i></Link> | 
                                                        <a href={ assestURL+'order_pdf/' + row.id + '.pdf' } target="_blank" ><i className="icon-cloud-download text-info p-2" title="Download" ></i></a>
                                                        
                                                        
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={orderCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Orders;

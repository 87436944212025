import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { kitchensectionList,kitchensectionListById, kitchensectionAdd, kitchensectionUpdate, kitchensectionDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "kitchensection";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    kitchensectionDatas: null,
    kitchensectionCount: 0,
    kitchensectionDatasbyID: null
};

export const kitchensectionData = createAsyncThunk(
    `${namespace}/kitchensectionData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", include ="", status ="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchensectionList(query, page, limit, sortby, order, include, status);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const kitchensectionDatabyID = createAsyncThunk(
    `${namespace}/kitchensectionDatabyID`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchensectionListById({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const AddKitchensection = createAsyncThunk(
    `${namespace}/AddKitchensection`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchensectionAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateKitchensection = createAsyncThunk(
    `${namespace}/UpdateKitchensection`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchensectionUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteKitchensection = createAsyncThunk(
    `${namespace}/DeleteKitchensection`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await kitchensectionDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const kitchensectionSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearKSLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearKSAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearKSUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearKSDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [kitchensectionData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [kitchensectionData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.kitchensectionCount = payload?.data?.count;
            // state.kitchensectionDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.kitchensectionCount = payloaddatas?.count;
            state.kitchensectionDatas = payloaddatas?.data;
        },
        [kitchensectionData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [kitchensectionDatabyID.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [kitchensectionDatabyID.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.kitchensectionCount = payload?.data?.count;
            // state.kitchensectionDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data, secretKey));
            console.log(payloaddatas?.data,"payload")
            state.kitchensectionDatasbyID = payloaddatas?.data;
        },
        [kitchensectionDatabyID.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddKitchensection.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddKitchensection.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddKitchensection.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateKitchensection.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateKitchensection.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateKitchensection.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteKitchensection.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteKitchensection.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteKitchensection.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearKSAddLoading, clearKSUpdateLoading, clearKSDeleteLoading, clearErrormsg } = kitchensectionSlice.actions;

export const kitchensectionSelector = (state) => state.kitchensection;

export default kitchensectionSlice.reducer;

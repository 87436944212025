import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subcategoryList, subcategoryAdd, subcategoryUpdate, subcategoryDelete, subcategoryFilter } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "subcategory";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    subcategoryDatas: null,
    allsubcategoryDatas: null,
    subcategoryCount: 0
};

export const subcategoryData = createAsyncThunk(
    `${namespace}/categoryData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subcategoryList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const subcategoryFilterData = createAsyncThunk(
    `${namespace}/subcategoryFilterData`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subcategoryFilter({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddsubCategory = createAsyncThunk(
    `${namespace}/AddsubCategory`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subcategoryAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdatesubCategory = createAsyncThunk(
    `${namespace}/UpdatesubCategory`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subcategoryUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeletesubCategory = createAsyncThunk(
    `${namespace}/DeletesubCategory`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await subcategoryDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const subCategorySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearsCatgLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearsCatgAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearsCatgUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearsCatgDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        },
        clearSubCatgDatas: (state) => {
            state.subcategoryDatas = null;
        }
    },
    extraReducers: {
        [subcategoryData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [subcategoryData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            //console.log(payloaddatas,"payload")
            state.subcategoryCount = payloaddatas?.count;
            state.subcategoryDatas = payloaddatas?.data;
            state.allsubcategoryDatas = payloaddatas?.data;
        },
        [subcategoryData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
        },
        [AddsubCategory.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddsubCategory.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddsubCategory.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdatesubCategory.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdatesubCategory.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdatesubCategory.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeletesubCategory.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeletesubCategory.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeletesubCategory.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [subcategoryFilterData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [subcategoryFilterData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data, "payload")
            state.subcategoryCount = payloaddatas?.count;
            state.subcategoryDatas = payloaddatas?.data;
        },
        [subcategoryFilterData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearsLoadingDatas, clearsCatgAddLoading, clearSubCatgDatas, clearsCatgUpdateLoading, clearsCatgDeleteLoading, clearErrormsg } = subCategorySlice.actions;

export const subCategorySelector = (state) => state.subcategory;

export default subCategorySlice.reducer;

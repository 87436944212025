import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportData, getTableNames } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
const namespace = "reports";

const initialState = {
    reportLoading: "initial",
    tableLoading: "initial",
    errorMessage: null,
    reportsResult: null,
    tableDetails: null,
    reportsMessage: null
};

export const reportsData = createAsyncThunk(
    `${namespace}/reportsData`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata--reports");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            // let payload = postData
            const data = await getReportData({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getTables = createAsyncThunk(
    `${namespace}/getTables`,
    async ({ query = '' }, { rejectWithValue, dispatch }) => {
        try {
            console.log('action_working');
            const data = await getTableNames(query);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



const reportSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearOrderLoadingDatas: (state) => {
            state.loading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [reportsData.pending](state) {
            state.reportLoading = API_STATUS.PENDING;
        },
        [reportsData.fulfilled](state, { payload }) {
            state.reportLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.reportsResult = payloaddatas.results;
            state.reportsMessage = payloaddatas.message;
        },
        [reportsData.rejected](state, action) {
            state.reportLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.message;
        },
        [getTables.pending](state) {
            state.tableLoading = API_STATUS.PENDING;
        },
        [getTables.fulfilled](state, { payload }) {
            state.tableLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.tableDetails = payloaddatas.tableDetails;
        },
        [getTables.rejected](state, action) {
            state.tableLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});



export const { clearData, clearLoadingDatas, clearErrormsg } = reportSlice.actions;


export const reportSelector = (state) => state.reports;

export default reportSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { kitchencodeList,kitchencodeListById, kitchencodeAdd, kitchencodeUpdate, kitchencodeDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "kitchencode";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    kitchencodeDatas: null,
    kitchencodeCount: 0,
    kitchencodeDatasbyID: null
};

export const kitchencodeData = createAsyncThunk(
    `${namespace}/kitchencodeData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", include ="", status ="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchencodeList(query, page, limit, sortby, order, include, status);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const kitchencodeDatabyID = createAsyncThunk(
    `${namespace}/kitchencodeDatabyID`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchencodeListById({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const AddKitchencode = createAsyncThunk(
    `${namespace}/AddKitchencode`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchencodeAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateKitchencode = createAsyncThunk(
    `${namespace}/UpdateKitchencode`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await kitchencodeUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteKitchencode = createAsyncThunk(
    `${namespace}/DeleteKitchencode`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await kitchencodeDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const kitchencodeSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearKCLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearKCAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearKCUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearKCDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [kitchencodeData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [kitchencodeData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.kitchencodeCount = payload?.data?.count;
            // state.kitchencodeDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.kitchencodeCount = payloaddatas?.count;
            state.kitchencodeDatas = payloaddatas?.data;
        },
        [kitchencodeData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [kitchencodeDatabyID.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [kitchencodeDatabyID.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.kitchencodeCount = payload?.data?.count;
            // state.kitchencodeDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data, secretKey));
            console.log(payloaddatas?.data,"payload")
            state.kitchencodeDatasbyID = payloaddatas?.data;
        },
        [kitchencodeDatabyID.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddKitchencode.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddKitchencode.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddKitchencode.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateKitchencode.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateKitchencode.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateKitchencode.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteKitchencode.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteKitchencode.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteKitchencode.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearKCAddLoading, clearKCUpdateLoading, clearKCDeleteLoading, clearErrormsg } = kitchencodeSlice.actions;

export const kitchencodeSelector = (state) => state.kitchencode;

export default kitchencodeSlice.reducer;
